/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { ComputedRef } from 'vue'
import { computed, toValue } from 'vue'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default function useWorkProgramsState() {
  const workProgramStoreRepository = useService('workProgramStoreRepository')
  const { getCurrentPhaseIri } = useEngagementState()
  const phase = getCurrentPhaseIri()

  const availableWorkPrograms = computed(() =>
    workProgramStoreRepository.getAvailableWorkPrograms(toValue(phase)),
  )
  const unavailableWorkPrograms = computed(() =>
    workProgramStoreRepository.getUnavailableWorkPrograms(toValue(phase)),
  )

  const startedWorkPrograms = computed(() => workProgramStoreRepository.getStartedWorkPrograms(toValue(phase)))
  const hasStartedWorkPrograms = computed(() => workProgramStoreRepository.hasStartedWorkPrograms(toValue(phase)))
  const finishedWorkPrograms = computed(() => workProgramStoreRepository.getFinishedWorkPrograms(toValue(phase)))

  const getCurrentWorkProgram = (): ComputedRef<WorkProgramProjection> => {
    return computed(() => workProgramStoreRepository.getCurrentWorkProgram())
  }

  const getCurrentWorkProgramStep = (): ComputedRef<StepInterface> => {
    return computed(() => workProgramStoreRepository.getCurrentWorkProgramStep())
  }

  return {
    availableWorkPrograms,
    unavailableWorkPrograms,
    startedWorkPrograms,
    finishedWorkPrograms,
    hasStartedWorkPrograms,
    getCurrentWorkProgram,
    getCurrentWorkProgramStep,
  }
}
